<template>
  <StageTemplate
    title="FONIC mobile ist jetzt FONIC - das ist die Wahrheit!"
    button-text="Zu den FONIC Fakten"
    href="https://www.fonic.de/fonic-mobile"
    :color="color"
    :image-large="largeImage"
    :image-medium="mediumImage"
    :image-small="smallImage"
    :stage-visual="foxLamaBye"
  >
  </StageTemplate>
</template>

<script>
// Import the used SVG otherwise it will not land in the prod build!
import StageTemplate from 'src/components/common/stages/templates/background_title_additional_image.vue'
import foxLamaBye from 'src/components/common/stages/resources/fox_lama_bye.svg'
import largeImage from './resources/smart_s/large-empty.webp'
import mediumImage from './resources/smart_s/medium-empty.webp'
import smallImage from './resources/smart_s/small-empty.webp'

export default {
  name: 'FonicGoodbye',
  components: {
    StageTemplate
  },
  data() {
    return {
      color: '#ffc13c',
      path: {
        name: 'PortalTariffOverview'
      },
      largeImage,
      mediumImage,
      smallImage,
      foxLamaBye
    }
  }
}
</script>
<style lang="scss" scoped>
.svg {
  background: var(--anniversary);

  @include breakpoint($from-desktop) {
    :deep(.button) {
      margin-bottom: 40px;
    }
  }
}
</style>
