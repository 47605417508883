<template>
  <div class="stage__wrapper" @click.stop="clickStage">
    <ImageComponent
      class="image__wrapper--bye"
      :src="getImageUrl(stageVisual)"
    />
    <div class="image__wrapper">
      <ImageComponent
        v-if="$breakpoint.fromDesktop()"
        class="image__wrapper--planet"
        width="1920"
        height="540"
        :src="getImageUrl(imageLarge)"
      />
      <ImageComponent
        v-else-if="
          imageMedium && $breakpoint.upToDesktop() && $breakpoint.fromTablet()
        "
        class="image__wrapper--planet"
        width="1920"
        height="540"
        :src="getImageUrl(imageMedium)"
      />
    </div>
    <div class="stage__content">
      <div class="text">
        <h1
          class="stage__title"
          :style="{ color: color ? color : 'var(--root-color)' }"
          v-html="title"
        />
        <p class="paragraph">
          Für unsere FONIC mobile Kunden ändert sich der Name, sonst nichts.<br />
          Sie telefonieren und nutzen Ihre Daten zu den bisherigen Konditionen!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from 'src/components/common/image/image.vue'
export default {
  name: 'BackgroundTitle',
  components: {
    ImageComponent
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    buttonText: {
      type: String,
      default: 'Zu den Tarifen'
    },
    href: {
      type: String,
      required: true
    },
    imageLarge: {
      type: String,
      required: true
    },
    imageMedium: {
      type: String,
      default: undefined
    },
    imageSmall: {
      type: String,
      required: true
    },
    stageVisual: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      clickAction: undefined
    }
  },
  mounted() {
    if (this.$attrs.onClick) {
      this.clickAction = this.$attrs.onClick
    }
  },
  methods: {
    clickStage() {
      if (this.clickAction !== undefined) {
        this.clickAction()
      }
    },
    getImageUrl(path) {
      return new URL(path, import.meta.url).href
    }
  }
}
</script>

<style lang="scss" scoped>
$up-to-430px: max-width 430px;
$from-1100px: min-width 1100px;

.stage__wrapper {
  background: var(--goodbye-background);

  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 750px;

  @include breakpoint($from-tablet) {
    height: 670px;
  }
}

.image__wrapper {
  height: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: '. . planet';

  @include breakpoint($up-to-tablet) {
    height: calc((100vw * 896) / 640);
  }

  @include breakpoint($from-tablet) {
    :deep(img) {
      max-width: none;
    }
  }

  @include breakpoint($from-desktop) {
    margin-top: -5px;
  }
}

.image__wrapper--planet {
  grid-area: planet;
}

.image__wrapper--bye {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 70vw;
  height: 100%;

  :deep(img) {
    max-height: 350px;
    align-self: initial;
  }

  @include breakpoint($up-to-desktop) {
    width: 175vw;
  }
}

.stage__content {
  @include full-width;
  @include font-normal;

  display: grid;
  grid-template-columns: 1.2fr 3fr 1.2fr;
  grid-template-rows: 0.5fr 2fr 2fr;
  grid-template-areas:
    '. . .'
    '. text .'
    '. button .';
  height: 100%;
  padding: 0 20px;
  position: absolute;
  top: 0;
  font-size: 1.1rem;
  color: white;

  @include ie-only {
    right: 0;
    left: 0;
  }

  @include breakpoint($up-to-desktop) {
    justify-content: flex-end;
    grid-template-columns: 0.2fr 3fr 0.2fr;
  }

  @include breakpoint($up-to-tablet) {
    padding: 0 10px;
  }
  .paragraph {
    color: white;
  }
}

.text {
  grid-area: text;
  padding: 0 13px;
  line-height: 1.3;
  align-self: self-start;

  @include breakpoint($from-tablet) {
    padding: 0 5px;
  }
}

.stage__title {
  @include font-bold;

  font-size: min(2.3rem, 52px);

  @include breakpoint($up-to-430px) {
    font-size: min(1.6rem, 38px);
  }

  @include breakpoint($up-to-desktop) {
  }

  @include breakpoint($from-tablet) {
    font-size: min(2.2rem, 50px);
    align-self: center;
  }

  @include breakpoint($from-desktop) {
    font-size: min(2.5rem, 50px);
    max-width: 985px;
  }
}

.paragraph {
  line-height: 2;

  @include breakpoint($from-desktop) {
    @include font-bold;

    padding: 0 5px;
  }
}

p {
  margin-bottom: 0;
}
</style>
