<template>
  <div class="portal-home--wrapper">
    <div class="section--overlap">
      <h2 class="title">Alle wichtigen Informationen zu den Veränderungen</h2>
      <p class="paragraph">
        FAQs und mehr zu den Veränderungen für unsere Kunden und potentielle
        Neukunden
      </p>
      <ButtonComponent
        class="facts__button"
        disable-full-width
        :clickhandler="() => $router.push({ name: 'PortalFonicFacts' })"
        href="/fonic-fakten"
        >Alle Fakten zu FONIC</ButtonComponent
      >
    </div>
    <div>
      <div data-cy="tariff_picker" class="home-section">
        <Notice v-if="showNotice()" />
        <h1 class="title">Holen Sie sich schon jetzt Ihren Tarif von FONIC</h1>
        <TariffGroup :tariffs="mainTariffs" />
      </div>
      <div data-cy="tariff_picker" class="home-section last-child">
        <BasicTarifffeatures class="tariff-section" />
      </div>
    </div>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Notice from 'src/components/common/notice/notice.vue'
import TariffGroup from 'src/components/portal/tariff/overview/components/tariff_group.vue'

import { defineAsyncComponent } from 'vue'
import LdlStage from '../../common/stages/fonic-mobile/goodbye.vue'
import Stage from '../../common/stages/fonic/autumn.vue'

const PROMOTED_TARIFF_TYPES = ['prime', 'classic']

export default {
  name: 'PortalHome',
  components: {
    BasicTarifffeatures: defineAsyncComponent(() =>
      import(
        'src/components/portal/tariff/overview/components/basic_tariff_features.vue'
      )
    ),
    ButtonComponent,
    TariffGroup,
    Notice
  },
  provide() {
    return {
      imageAlt: this.imageAlt
    }
  },
  inject: ['$http'],
  data() {
    return {
      showReviews: this.$brand.isFonic(),
      iconSize: '70',
      imageAlt: `${this.$brand.name}: unsere klassische Prepaid-Karte`,
      mainTariffs: []
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  },
  created() {
    this.getTariffList()
    if (this.$brand.isFonic()) {
      this.$bus.emit('show-stage', Stage)
    } else {
      this.$bus.emit('show-stage', LdlStage)
    }

    this.$bus.on('trustedshops-load-error', this.onLoadError)
  },
  beforeUnmount() {
    this.$bus.off('trustedshops-load-error', this.onLoadError)
  },
  methods: {
    getImageUrl() {
      return new URL(
        `./resources/e-trustedshops${
          this.isHighContrastModeActive ? '-white' : ''
        }.svg`,
        import.meta.url
      ).href
    },
    onLoadError() {
      this.showReviews = false
    },
    showNotice() {
      return Date.now() < new Date('2023-02-25')
    },
    async getTariffList() {
      try {
        const response = await this.$http.get('/rest-api/v3/portal/tariffs')

        this.tariffs = response.data
        this.sortTariffs()
        this.$tracking
          .getGtm()
          .trackProducts('listView', this.dataLayerProducts)
      } catch (e) {
        console.error(e)
        console.error(`Error requesting ${this.$brand.name} tariffs from API`)
      }

      this.loading = false
    },
    sortTariffs() {
      this.tariffs.filter(t => {
        if (PROMOTED_TARIFF_TYPES.includes(t.tariffType)) {
          this.mainTariffs.push(t)
        }
      })

      this.mainTariffs = this.mainTariffs.reverse()
    }
  }
}
</script>

<style lang="scss" scoped>
.portal-home--wrapper {
  display: flex;
  flex-direction: column;
}

.section--overlap {
  position: relative;
  z-index: 5;
  margin-top: -80px;
  margin-bottom: calc-rem(40px);
  padding: 30px 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: white;
  background: var(--goodbye-background);
  text-align: center;

  h2 {
    color: white;
    margin-bottom: 15px;
  }

  p {
    color: white;
    margin-bottom: 20px;
  }

  .facts__button {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.portal-opportunities {
  > div {
    display: flex;

    @include breakpoint($up-to-tablet) {
      flex-direction: column;
    }
  }

  .portal--top-phone {
    margin: 8px;

    @include breakpoint($from-tablet) {
      margin: 8px 8px 8px 0;
    }
  }
}

.home-section {
  &:nth-child(even) {
    @include full-width-bg;

    padding-bottom: 50px;
    padding-top: 50px;

    .portal--top-phone {
      background-color: var(--background-primary);
    }

    .badge {
      border: inherit;
      background-color: var(--background-primary);
    }
  }

  &:nth-child(odd) {
    padding-bottom: 50px;
    padding-top: 50px;

    .badge {
      border: inherit;
      background-color: var(--background-secondary);
    }
  }

  // TODO: refactor after A/B-Test
  .home-section--first-element {
    margin-top: -40px;
  }

  &:last-child {
    margin-bottom: -40px;
    padding-bottom: 100px;
  }
}

.home-section--fonic {
  padding-bottom: 40px;
}

.prepaid-advantages-table__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @include breakpoint($from-tablet) {
    flex-direction: row;

    .prepaid-advantages-table__text {
      margin: 0 0 0 20px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid color.adjust($root-color, $lightness: 60%);
  }
}

.prepaid-advantages-table__text {
  @include ie-only {
    width: 100%;
  }

  p {
    margin-bottom: 0;
  }
}

.home-section--heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customer-review--badge {
  position: relative;

  :deep(img) {
    max-width: none;
  }
}

.home-section--customer-reviews {
  display: flex;
  flex-direction: column;
}

.customer-reviews-link {
  @include font-bold;

  color: var(--link-color);
  padding-right: 10px;
  margin-top: 25px;
  line-height: 1;
  margin-left: auto;

  @include breakpoint($up-to-desktop) {
    margin-left: 0;
    text-align: center;
  }

  @include breakpoint($up-to-tablet) {
    margin-top: 0;
  }
}

.prepaid-advantages-table__img {
  padding: 20px;
  max-width: 120px;
  min-width: 120px;

  @include breakpoint($up-to-tablet) {
    padding: 0 0 20px;
  }

  @include ie-only {
    :deep(img) {
      width: 100%;
    }
  }
}

h1.title {
  margin-top: 0;
}
</style>
